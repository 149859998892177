import { smoothScroll } from '@/utils/dom'
import { Link, usePage } from '@inertiajs/react'
import { getLocale, localizedPath } from '@/utils/locale'

type LinkComponentProps = {
  children: any
  to?: string
  scrollToOffset?: number
  [key: string]: any
}

export default function LinkComponent({ children, to, scrollToOffset = undefined, ...other }: LinkComponentProps) {
  if (!to) return <span {...other}>{children}</span>
  const { url } = usePage()
  const localPath = localizedPath(to, getLocale(url))

  // hash links to the current page but happen to include the whole path should have that axed
  let toPath =
    localPath.startsWith(`${url}#`) || localPath.startsWith(`${url}/#`) ? localPath.substring(url.length) : localPath
  if (toPath.startsWith('/#')) {
    toPath = toPath.substring(1)
  }

  // internal hash links
  if (toPath.startsWith('#')) {
    return (
      <a
        href={toPath}
        onClick={(e) => {
          const target = document.getElementById(toPath.substring(1))
          if (target && getComputedStyle(target)?.display === 'none') target.style.display = 'block'
          scrollToOffset ? smoothScroll(e, scrollToOffset) : smoothScroll(e)
        }}
        {...other}
      >
        {children}
      </a>
    )
  }

  // internal links to non-static paths
  if (toPath.startsWith('/') && !toPath.startsWith('//')) {
    return toPath.includes('#') ? ( // lame gatsby bug??
      <a href={toPath} {...other}>
        {children}
      </a>
    ) : (
      <Link href={toPath} {...other}>
        {children}
      </Link>
    )
  }

  // everything else is an external link or resource, open in a new tab
  return (
    <a href={toPath} target="_blank" rel="noreferrer" {...other}>
      {children}
    </a>
  )
}
