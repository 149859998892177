import React, { useCallback } from 'react'
import type { Entry } from 'contentful'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Link from '@/components/link-component'
import ImageContentful from './image-contentful'
import { cx } from '@/utils/strings'
import { type TypeResourceImageSkeleton } from '@/types/ctf/TypeResourceImage'
import { useLiveUpdates } from '@/utils/live-preview'

export type ImageProps = Entry<TypeResourceImageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>['fields'] & {
  className?: string
  css?: any
  loading?: any
  entryId?: string
}

export default function Image({ entryId, className, css, loading, ...contentfulFields }: ImageProps) {
  const {
    fields: { image, link, showCaption, zoomable },
  } = useLiveUpdates({ fields: contentfulFields, sys: { id: entryId } })

  const fields = image?.fields || {}

  const ZoomContent = useCallback(
    ({
      buttonUnzoom,
      modalState,
      img,
    }: {
      buttonUnzoom: React.ReactNode
      modalState: string
      img: React.ReactNode
    }) => (
      <>
        <div
          className={cx(
            'z-20 opacity-50 transition-transform [button]:text-white [button]:bg-indigoBlue',
            modalState !== 'LOADED' && `-translate-y-16`,
            modalState === 'LOADED' && `translate-y-0`
          )}
        >
          {buttonUnzoom}
        </div>
        <figure>
          {img}
          {showCaption && (
            <figcaption
              className={cx(
                'fixed right-0 left-0 p-2 flex justify-center bg-gradient-to-t from-primaryBlue to-indigoBlue text-white text-xl text-center pt-1 italic transition-[bottom] opacity-70',
                modalState !== 'LOADED' && `-bottom-16`,
                modalState === 'LOADED' && `bottom-0`
              )}
            >
              <cite>{image?.fields?.title}</cite>
              {image?.fields?.title && image?.fields?.description && <> &#8212; </>}
              {image?.fields?.description}
            </figcaption>
          )}
        </figure>
      </>
    ),
    []
  )

  const Wrapper = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      if (link?.fields?.url) return <Link to={link.fields.url}>{children}</Link>
      if (zoomable) return <Zoom ZoomContent={ZoomContent}>{children}</Zoom>
      return children
    },
    [link?.fields?.url, zoomable]
  )

  return (
    <Wrapper>
      <figure className="break-inside-avoid mt-2 mb-6 [&:not(:has(svg))]:w-fit">
        <ImageContentful {...fields} className={className} css={css} loading={loading} />
        {showCaption && (
          <figcaption className="w-fit max-w-sm mx-auto text-center text-xs text-gray-400 mt-2 italic">
            <cite>{image?.fields?.title}</cite>
            {image?.fields?.title && image?.fields?.description && <> &#8212; </>}
            {image?.fields?.description}
          </figcaption>
        )}
      </figure>
    </Wrapper>
  )
}
