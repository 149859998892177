import Link from '@/components/link-component'
import Image from './image'
import { cx } from '@/utils/strings'

export default function Cta({
  title,
  buttonText,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore this line doesn't recognize alias rename
  url,
  image,
  backgroundColor,
}): JSX.Element {
  return (
    <div
      className={cx(
        'bg-indigoBlue rounded-3xl flex items-center flex-col-reverse sm:flex-row break-inside-avoid-page',
        backgroundColor === 'Primary' && `bg-primaryBlue`,
        backgroundColor === 'Secondary' && `bg-indigoBlue`,
        backgroundColor === 'Tertiary' && `bg-indigoBlue`
      )}
    >
      <div className={cx('p-8', !!image && `sm:w-1/2`)}>
        <h2 className="text-xl text-white mb-6 font-normal leading-tight text-center sm:text-left">{title}</h2>
        <div className="flex justify-center w-full sm:block">
          <Link to={url?.fields?.url} className="inline-block text-center">
            <div
              className={cx(
                'bg-white px-8 py-2 rounded-full font-bold text-center hover:bg-gray-100',
                backgroundColor === 'Primary' && `text-primaryBlue`,
                backgroundColor === 'Secondary' && `text-indigoBlue`,
                backgroundColor === 'Tertiary' && `text-indigoBlue`
              )}
            >
              {buttonText}
            </div>
          </Link>
        </div>
      </div>
      {image && (
        <div className="sm:w-1/2 flex items-center justify-center p-8 pb-0 sm:pb-8">
          <Image image={image} />
        </div>
      )}
    </div>
  )
}
