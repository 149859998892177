import { AdvancedImage, lazyload, placeholder } from '@cloudinary/react'
import { CloudinaryImage } from '@cloudinary/url-gen'
import { auto } from '@cloudinary/url-gen/qualifiers/format'
import { format } from '@cloudinary/url-gen/actions/delivery'

import { isPrint } from '@/utils/print'
import { cx } from '@/utils/strings'

export type ImageContentfulProps = {
  contentful_id?: string
  title: string
  description?: string
  publicUrl?: string
  file?: {
    contentType: string
    url: string
  }
  svg?: {
    content: string
  }
  className?: any
  loading?: 'eager' | 'lazy'
}

export default function ImageContentful({
  contentful_id,
  title,
  description,
  publicUrl,
  file,
  svg,
  loading = 'lazy',
  ...rest
}: ImageContentfulProps) {
  if (!file && !publicUrl && !svg?.content) return
  const caption = description ? `${title} - ${description}` : title

  if (svg?.content) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: svg.content }} {...rest} className={cx('w-full', rest.className)} />
  }

  if (['image/svg+xml'].includes(file?.contentType ?? '') || isPrint()) {
    let src = publicUrl || file?.url
    if (import.meta.env.APP_ENV === 'local') src = file?.url || publicUrl
    return <img data-testid="image" src={src} alt={caption} {...rest} />
  }

  // use this to lazy migrate
  const path = file?.url?.replace('//images.ctfassets.net/', 'ctf/')?.replace('//images.contentful.com/', 'ctf/')
  const cldImg = new CloudinaryImage(path, {
    cloudName: 'bw-com',
  }).delivery(format(auto()))

  // use this after migration
  // const cld = new Cloudinary({
  //   cloud: {
  //     cloudName: 'bw-com',
  //   },
  // })
  // const cldImg = cld.image(contentful_id)

  return (
    <AdvancedImage
      cldImg={cldImg}
      plugins={[
        ...(loading === 'lazy' ? [lazyload()] : []),
        placeholder({ mode: 'vectorize' }),
        // for some reason responsive doesn't look quite sharp enough with some images
        // responsive({ steps: [600, 1000, 1400] }),
      ]}
      alt={caption}
      {...rest}
    />
  )
}
