import Link from '@/components/link-component.js'
import { smoothScroll } from '@/utils/dom.ts'
import { cx, slugify } from '@/utils/strings.js'

export type SizeType = 'h2' | 'h3'

export default function HeadingWithAnchor({
  type,
  title,
  className,
}: {
  type: SizeType
  title: string
  className?: string
}) {
  const anchor = slugify(title)
  return (
    <div id={anchor} className="group relative">
      <Link
        title={`#${anchor}`}
        to={`#${anchor}`}
        onClick={(event) => {
          const { origin, pathname } = window.location

          navigator.clipboard.writeText(`${origin}${pathname}#${anchor}`)
          smoothScroll(event)
        }}
        className={cx(
          'absolute text-primaryBlue opacity-0 group-hover:opacity-40 hover:opacity-100! transition-opacity duration-500',
          type === 'h2' && 'w-6 h-6 top-2 -left-7',
          type === 'h3' && 'w-5 h-5 top-2 -left-6'
        )}
      >
        {/*<HashtagIcon />*/}
      </Link>
      <Heading className={className} type={type}>
        {title}
      </Heading>
    </div>
  )
}

function Heading({ type, children, className }: { type: SizeType; children: React.ReactNode; className?: string }) {
  if (type === 'h2') return <h2 className={className}>{children}</h2>
  if (type === 'h3') return <h3 className={className}>{children}</h3>
}
