const colors = {
  indigoBlue: 'Indigo Blue',
  primaryBlue: 'Primary Blue',
  deepBlue: 'Deep Blue',
  lightGray: 'Light Gray',
  white: 'White',
  simpleNavPrimaryBlue: 'Simple - Primary Blue',
  simpleNavWhite: 'Simple - White',
  transparent: 'Transparent',
}

export default colors
