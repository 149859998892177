import { usePage } from '@inertiajs/react'

export const locales = [
  {
    slug: 'en-us',
    contentfulCode: 'en-US',
    label: 'English',
  },
  {
    slug: 'de-de',
    contentfulCode: 'de-DE',
    label: 'Deutsch',
  },
  {
    slug: 'es-la',
    contentfulCode: 'es-419',
    label: 'Español',
  },
  {
    slug: 'fr-fr',
    contentfulCode: 'fr-FR',
    label: 'Français',
  },
  {
    slug: 'ja-jp',
    contentfulCode: 'ja-JP',
    label: '日本語',
  },
  {
    slug: 'nl-nl',
    contentfulCode: 'nl-NL',
    label: 'Nederlands',
  },
]

export const defaultLocale = locales[0].slug

export const t = (string: string) => {
  if (getLocale() === defaultLocale) return string;
  const translations = usePage().props.translations as { [key: string]: string };
  if (!translations) return string;
  return translations[string] || string;
}

// transforms `/ja-jp/products/` => [`/ja-jp/products/`, `ja-jp`, `/products/`]
const pathLocaleRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})(\/?.*)$/

// based on current path, return locale info
export const useLocale = (pathname) => {
  const currentLocale = getLocale(pathname)
  const currentContentfulCode =
    locales.find((loc) => loc.slug === currentLocale)?.contentfulCode || locales[0].contentfulCode
  return {
    currentLocale,
    currentContentfulCode,
    lang: currentLocale.split('-')[0],
    region: currentLocale.split('-')[1],
  }
}

export const getLocale = (path: string | undefined = undefined) => {
  if (!path) path = usePage()?.url
  const matches = path?.match(pathLocaleRegex)
  return matches && matches.length === 3 ? matches[1] : defaultLocale
}

export const getBrowserLocaleBestMatch = (fallback = defaultLocale) => {
  if (typeof window === 'undefined') return useLocale().currentLocale
  let { language } = navigator
  if (language.length > 2) language = language.substring(0, 2)
  language = language.toLowerCase()
  const matchedLocale = locales.find((local) => local.slug.substring(0, 2) === language)
  if (matchedLocale) return matchedLocale.slug
  return fallback
}

export const localizedPath = (path: string, locale: string) => {
  let newPath = path

  // sanitize input
  if (!newPath || typeof newPath !== 'string' || newPath.trim().length < 1) return ''

  // check for unnecessary https://bitwarden.com prefix
  if (
    newPath.toLowerCase().includes('www.bitwarden.com') ||
    (newPath.toLowerCase().includes('bitwarden.com') && !newPath.toLowerCase().includes('.bitwarden.com'))
  ) {
    newPath = path.substring(path.toLowerCase().indexOf('bitwarden.com') + 13)
    if (!newPath.startsWith('/')) newPath = `/${newPath}`
  }

  if (
    newPath.toLowerCase().startsWith('https://vault.bitwarden.com/') &&
    (newPath.toLocaleLowerCase().includes('register') ||
      newPath.toLocaleLowerCase().includes('login') ||
      newPath.toLocaleLowerCase().includes('trial'))
  ) {
    return ['de-de', 'fr-fr', 'nl-nl'].includes(locale)
      ? `https://vault.bitwarden.eu/${newPath.substring(28)}`
      : newPath
  }

  // check if this is an absolute path, ex: https://google.com
  if (!newPath.startsWith('/') || newPath.startsWith('//')) return path

  // make sure the path ends with a trailing slash
  if (!newPath.includes('#') && !newPath.includes('?') && !newPath.endsWith('/') && !newPath.includes('.'))
    newPath += '/'
  else if (newPath.includes('?') && !newPath.includes('/?')) newPath = newPath.replace('?', '/?')
  else if (newPath.includes('#') && !newPath.includes('/#') && !newPath.includes('?'))
    newPath = newPath.replace('#', '/#')

  // check if this is a valid locale slug
  const localeSlug =
    locales.find((loc) => locale === loc.slug)?.slug ||
    locales.find((loc) => locale === loc.contentfulCode)?.slug ||
    locales.find((loc) => locale.localeCompare(loc.slug, defaultLocale, { sensitivity: 'base' }) === 0)?.slug ||
    locales.find((loc) => locale.localeCompare(loc.contentfulCode, defaultLocale, { sensitivity: 'base' }) === 0)
      ?.slug ||
    defaultLocale

  // strip a possible locale prefix
  const matches = newPath.match(pathLocaleRegex)
  if (matches && matches.length === 3) [, , newPath] = matches
  return localeSlug === defaultLocale ? newPath : `/${localeSlug}${newPath}`
}
