/* eslint-disable react/require-default-props */
import { isPrint } from '@/utils/print'
import { cx } from '@/utils/strings'
import './bw-table.css'

export interface Props {
  children: React.ReactNode
  /** comparison style - if true fixes first column on small screens */
  comparisonTable?: boolean
  /** narrow or wide */
  contentWidth?: string
  /** white or gray */
  tableColor?: string
  /** table has a footer */
  tableHasFooter?: boolean
}

const whiteTable = `[&_td]:border-gray-100 [&_td:after]:bg-gray-100 [&_th]:border-gray-100 bg-white`
const grayTable = `[&_td]:border-gray-200 [&_td:after]:bg-gray-200 bg-gray-100 [&_th]:border-gray-200`
const tdXPaddingWide = `[&_th]:px-4 lg:[&_th]:!px-10 [&_td]:px-4 lg:[&_td]:!px-10 [&_td:after]:left-3 [&_td:after]:right-3 lg:[&_td:after]:left-6 lg:[&_td:after]:right-6`
const tdXPaddingNarrow = `[&_th]:px-4 [&_td]:px-4 [&_td:after]:left-3 [&_td:after]:right-3`

const commonRowStyles = `
  relative
  text-indigoBlue 
  border-collapse 
  w-full 
  rounded-3xl
  break-normal
  [&_p]:break-normal
  [&_th:last-of-type]:border-r-0
  [&_th]:leading-tight [&_th]:py-4 [&_th]:font-bold [&_th]:border-r [&_th]:text-left
  md:[&_th:first-of-type]:w-1/3
  md:[&_th]:text-lg md:[&_th]:!pt-11
  md:[th > p]:text-lg
  [&_td]:text-left [&_td]:leading-tight [&_td]:py-4 [&_td]:border-r
  [&_td:last-of-type]:border-r-0 
  [&_td]:after:h-[1px] [&_td:after]:block [&_td:after]:content [&_td:after]:absolute [&_td:after]:bottom-0 [&_td:after]:z-0
  [tfoot td]:pb-10 [tfoot td]:font-bold
  [tfoot td:after]:hidden
  `

function BwTable({ children, contentWidth, tableColor, tableHasFooter, comparisonTable }: Props) {
  const print = isPrint()
  return (
    <table
      className={cx(
        tableColor === 'white' ? whiteTable : grayTable,
        contentWidth === 'wide' ? tdXPaddingWide : tdXPaddingNarrow,
        commonRowStyles,
        !tableHasFooter && `bw-table--has-footer`,
        comparisonTable &&
          `
      [&_th]:text-center
      [&_td]:text-center
      [td:first-of-type]:text-left
      [th:first-of-type]:text-left
      min-w-[550px] border-separate border-spacing-0 table-fixed 
      max-md:[&_th]:w-[160px] 
      max-md:[tr td:first-of-type]:sticky max-md:[tr td:first-of-type]:left-0 max-md:[tr td:first-of-type]:z-10 max-md:[tr td:first-of-type]:bg-white max-md:[tr td:first-of-type]:border-r border-gray-100 max-md:[tr td:first-of-type]:shadow-lg
      max-md:[tr th:first-of-type]:rounded-tl-3xl max-md:[tr th:first-of-type]:sticky max-md:[tr th:first-of-type]:left-0 max-md:[tr th:first-of-type]:z-10 max-md:[tr th:first-of-type]:bg-white max-md:[tr th:first-of-type]:border-r max-md:[tr th:first-of-type]:border-gray-100 max-md:[tr th:first-of-type]:shadow-lg
      `,
        print && '[&_p]:break-all'
      )}
    >
      {children}
    </table>
  )
}

export default BwTable
