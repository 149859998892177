import { useEffect, useState } from 'react'

import Image from '@/components/ui/image'
import { isPrint } from '@/utils/print'
import { cx } from '@/utils/strings'

// videos supported:
// vimeo example     https://player.vimeo.com/video/632391847
// youtube example   https://www.youtube.com/embed/YhIwT4AMvm0

export default function Video({ fields }) {
  const { url, aspectRatio } = fields
  // default params dont work because stinker contentful sends null instead of undefined
  const aspectRatioWithDefault = aspectRatio || '16 / 9'

  const [thumbnail, setThumbnail] = useState()

  const isprint = isPrint()

  useEffect(() => {
    if (isprint && !thumbnail)
      fetch(`https://noembed.com/embed?url=${url}`)
        .then((response) => response.json())
        .then((data) => {
          setThumbnail(data?.thumbnail_url_with_play_button || data?.thumbnail_url)
        })
  }, [isprint])

  if (isprint)
    return (
      <Image
        className="w-[400px]"
        link={{ url }}
        showCaption
        image={{ fields: { title: url, publicUrl: thumbnail } }}
      />
    )

  return (
    <div
      className={cx(
        'mt-4 w-full',
        aspectRatioWithDefault === '16 / 9' && `aspect-[16/9]`,
        aspectRatioWithDefault === '9 / 16' && `aspect-[9/16]`,
        aspectRatioWithDefault === '4 / 3' && `aspect-[4/3]`,
        aspectRatioWithDefault === '1 / 1' && `aspect-[1/1]`
      )}
      data-testid="videoplayer"
    >
      {/* Player ratio: 100 / (1280 / 720) */}
      {url?.toLowerCase()?.includes('vimeo') && (
        <>
          <script src="https://player.vimeo.com/api/player.js" />

          <iframe
            className="w-full h-full"
            src={`${url}?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479`}
            title="Bitwarden video"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        </>
      )}
      {url?.toLowerCase()?.includes('youtube') && (
        <iframe
          className="w-full h-full"
          src={`${url}?rel=0&modestbranding=1`}
          title="Bitwarden video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
      )}
    </div>
  )
}
