/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
import React, { type ReactNode, useCallback } from 'react'

import { documentToReactComponents, type Options } from '@contentful/rich-text-react-renderer'
import {
  type Block,
  BLOCKS,
  type Document,
  type Heading1,
  type Heading2,
  type Heading3,
  type Heading4,
  type Heading5,
  type Heading6,
  type Hyperlink,
  type Inline,
  INLINES,
  type ListItem as ListItemType,
  type Node,
  type OrderedList as OrderedListType,
  type Paragraph,
  type Table,
  type TableCell,
  type TableHeaderCell,
  type TableRow,
  type Text as TextType,
  type UnorderedList as UnorderedListType,
} from '@contentful/rich-text-types'

import Link from '../link-component.js'
import HeadingWithAnchor from '../heading-with-anchor.tsx'
import BwTable from './table/bw-table.tsx'
import colors from '@/constants/colors'
// eslint-disable-next-line import/no-self-import
import RichTextComponent from './rich-text.tsx'
import Callout, { type Props as CalloutProps } from './callout.tsx'
import Code, { type Props as CodeProps } from './code/code.tsx'
import Cta from './cta.tsx'
import HubSpotForm from './hubspot-form/hubspot-form.tsx'
import Icon from './icon-font.tsx'
import Image from './image.tsx'
import Quote, { type QuoteProps } from './quote.tsx'
import Tabs, { type Props as TabsProps } from './tabs.tsx'
import Video from './video.tsx'
import OverflowIndicator from './overflow-indicator.tsx'
import { cx } from '@/utils/strings.ts'
import { defaultLocale, localizedPath, useLocale } from '@/utils/locale.ts'
import { usePage } from '@inertiajs/react'

interface Props {
  body: any
  contentWidth?: string
  backgroundColor?: string
  tableColor?: string
  h1?: (node: Heading1, children: ReactNode) => ReactNode
  h2?: (node: Heading2, children: ReactNode) => ReactNode
  h3?: (node: Heading3, children: ReactNode) => ReactNode
  h4?: (node: Heading4, children: ReactNode) => ReactNode
  h5?: (node: Heading5, children: ReactNode) => ReactNode
  h6?: (node: Heading6, children: ReactNode) => ReactNode
  p?: (node: Paragraph, children: ReactNode) => ReactNode
  blockquote?: (node: Paragraph, children: ReactNode) => ReactNode
  ol?: (node: OrderedListType, children: ReactNode) => ReactNode
  ul?: (node: UnorderedListType, children: ReactNode) => ReactNode
  li?: (node: ListItemType, children: ReactNode) => ReactNode
  table?: (node: Table, children: ReactNode) => ReactNode
  tableCell?: (node: TableCell, children: ReactNode) => ReactNode
  tableHeaderCell?: (node: TableHeaderCell, children: ReactNode) => ReactNode
  tableRow?: (node: TableRow, children: ReactNode) => ReactNode
  a?: (node: Hyperlink, children: ReactNode) => ReactNode
  bold?: (text: ReactNode) => ReactNode
  code?: (text: ReactNode) => ReactNode
  renderCallout?: (ref: CalloutProps) => ReactNode
  renderCode?: (ref: CodeProps) => ReactNode
  renderCta?: (ref: any) => ReactNode
  renderHubSpotForm?: (ref: any) => ReactNode
  renderIcon?: (ref: any) => ReactNode
  renderImage?: (ref: any) => ReactNode
  renderComponentImage?: (ref: any) => ReactNode
  renderQuote?: (ref: QuoteProps) => ReactNode
  renderTabs?: (ref: TabsProps) => ReactNode
  renderVideo?: (ref: any) => ReactNode
  helpSection?: boolean
}

export default function RichText({
  contentWidth = 'narrow',
  tableColor = 'gray',
  backgroundColor = colors.white,
  body,
  helpSection,
  h1 = (node, children) => <h1 className={`${helpSection && 'h3'}`}>{children}</h1>,
  h2 = (node, children) => {
    const title = children?.[0]?.[1] || children?.[0]?.props?.children[1]
    return <HeadingWithAnchor type="h2" title={title} className={`${helpSection && 'h4'}`} />
  },
  h3 = (node, children) => {
    const title = children?.[0]?.[1] || children?.[0]?.props?.children[1]
    return <HeadingWithAnchor type="h3" title={title} className={`${helpSection && 'h5'}`} />
  },
  h4 = (node, children) => <h4>{children}</h4>,
  h5 = (node, children) => <h5>{children}</h5>,
  h6 = (node, children) => <h6>{children}</h6>,
  p = (node, children) => <p>{children}</p>,
  blockquote = (node, children) => (
    <blockquote className="font-medium leading-normal text-indigoBlue text-left border-l-8 border-primaryBlue my-8 py-4 pr-0 pl-6 [*]:text-xl [&_p:last-child]:m-0">
      {children}
    </blockquote>
  ),
  ol = (node, children) => <ol className="mb-4 ml-5 pl-0">{children}</ol>,
  ul = (node, children) => <ul className="mb-4 ml-5 pl-0">{children}</ul>,
  li = (node, children) => <li className="[&_p]:mb-2">{children}</li>,
  table = (node, children) => {
    let thead = null
    const trChildren: any = []
    React.Children.forEach(children, (child: ReactNode & { type: string }) => {
      if (child.type === 'thead') thead = child
      else if (child.type === 'tr') trChildren.push(child)
    })
    return (
      <div className={cx('table-container', contentWidth === 'wide' && `pt-5 lg:pt-8`)}>
        <OverflowIndicator className="mb-5 lg:mb-10 overflow-x-auto [*>p]:mb-0" disableOverflowY threshold={24}>
          <BwTable tableColor={tableColor} contentWidth={contentWidth}>
            {thead}
            {trChildren.length > 0 && <tbody>{trChildren}</tbody>}
          </BwTable>
        </OverflowIndicator>
      </div>
    )
  },
  tableCell = (node) => (
    <td className="relative">
      {node.content.map((child, index) => (
        <RichTextComponent body={child} key={`td-${index}`} />
      ))}
    </td>
  ),
  tableHeaderCell = (node) => (
    <th>
      <RichTextComponent body={node.content[0]} />
    </th>
  ),
  tableRow = (node, children) => {
    const childType = node.content[0].nodeType
    if (BLOCKS.TABLE_HEADER_CELL === childType)
      return (
        <thead>
          <tr>{children}</tr>
        </thead>
      )
    if (BLOCKS.TABLE_CELL === childType) return <tr>{children}</tr>
  },
  a = (node, children) => {
    return (
      <Link
        to={node.data.uri}
        className={cx(
          'underline',
          backgroundColor === colors.white && 'text-primaryBlue',
          backgroundColor === colors.lightGray && 'text-primaryBlue',
          backgroundColor === colors.primaryBlue && 'text-white',
          backgroundColor === colors.indigoBlue && 'text-tealBlue',
          'dark:text-tealBlue dark:hover:text-white'
        )}
      >
        {children}
      </Link>
    )
  },
  bold = (text) => <strong>{text}</strong>,
  code = (text) => <code style={{ color: '#e83e8c' }}>{text}</code>,
  renderCallout = (ref) => {
    return (
      <>
        <Callout {...ref.data?.target?.fields}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <RichTextComponent body={ref.data?.target?.fields.body} />
        </Callout>
      </>
    )
  },
  renderCode = (ref) => {
    return <Code id={ref.data?.target?.sys.id} body={ref.data?.target?.fields.body} />
  },
  renderCta = (ref) => <Cta {...ref.data?.target?.fields} />,
  renderHubSpotForm = (ref) => <HubSpotForm {...ref.data?.target?.fields} />,
  renderIcon = (ref) => <Icon className="rich-icon" {...ref} />,
  renderImage = (ref) => <Image image={ref} showCaption />,
  renderComponentImage = (ref) => <Image {...ref} />,
  renderTabs = (ref) => {
    return <Tabs groupId={ref.data?.target?.sys.id} content={ref.data?.target?.fields.body.content} />
  },
  renderQuote = (ref) => <Quote attribution={ref.attribution} body={ref.body} citation={ref.citation} />,
  renderVideo = (ref) => (
    <div className="mb-8">
      <Video {...ref} />
    </div>
  ),
}: Props) {
  if (typeof body !== 'object' || !body) return null
  const page = usePage()
  const optionsObj: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: useCallback(h1, []),
      [BLOCKS.HEADING_2]: useCallback(h2, []),
      [BLOCKS.HEADING_3]: useCallback(h3, []),
      [BLOCKS.HEADING_4]: useCallback(h4, []),
      [BLOCKS.HEADING_5]: useCallback(h5, []),
      [BLOCKS.HEADING_6]: useCallback(h6, []),
      [BLOCKS.PARAGRAPH]: useCallback(p, []),
      [BLOCKS.QUOTE]: useCallback(blockquote, []),
      [BLOCKS.OL_LIST]: useCallback(ol, []),
      [BLOCKS.UL_LIST]: useCallback(ul, []),
      [BLOCKS.LIST_ITEM]: useCallback(li, []),
      [BLOCKS.TABLE]: useCallback(table, []),
      [BLOCKS.TABLE_CELL]: useCallback(tableCell, []),
      [BLOCKS.TABLE_HEADER_CELL]: useCallback(tableHeaderCell, []),
      [BLOCKS.TABLE_ROW]: useCallback(tableRow, []),
      [BLOCKS.EMBEDDED_ENTRY]: useCallback((node) => {
        const type = node?.data?.target?.sys?.contentType?.sys?.id
        if (type === 'componentCallout') return renderCallout(node as CalloutProps)
        if (type === 'componentCode') return renderCode(node as CodeProps)
        if (type === 'componentCta') return renderCta(node)
        if (type === 'componentHubSpotForm') return renderHubSpotForm(node)
        if (type === 'resourceImage') {
          return renderComponentImage(node.data?.target?.fields)
        }
        if (type === 'componentQuote') return renderQuote(node as QuoteProps)
        if (type === 'componentTabs') return renderTabs(node as TabsProps)
        if (type === 'componentVideo') return renderVideo(node.data?.target)
        if (type === 'componentIcon') return renderIcon(node.data?.target.fields)
      }, []),
      [BLOCKS.EMBEDDED_ASSET]: useCallback((node) => {
        if (!node.data?.target) return null

        return renderImage(node.data?.target)
      }, []),
      [INLINES.ASSET_HYPERLINK]: useCallback((node) => {
        return (
          <Link
            title={`${node.content[0].value}`}
            to={node?.data?.target?.fields?.file?.url}
            className="text-primaryBlue hover:text-indigoBlue transition-colors"
          >
            <RichTextComponent body={node.content[0]} />
          </Link>
        )
      }, []),
      [INLINES.EMBEDDED_ENTRY]: useCallback((node) => {
        if (node?.data?.target?.sys?.contentType?.sys?.id === 'componentIcon')
          return renderIcon(node.data?.target?.fields)
        if (node?.data?.target?.sys?.contentType?.sys?.id === 'resourceImage')
          return renderComponentImage(node.data?.target?.fields)
      }, []),
      [INLINES.HYPERLINK]: useCallback(a, []),
      [INLINES.ENTRY_HYPERLINK]: useCallback((node) => {
        let path
        if (node?.__typename === 'ContentfulSeo') path = node?.path
        else if (node?.__typename === 'ContentfulHelpArticle')
          path = `/help/${node?.slug}/${
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (node.content?.[0] as any)?.hash || ''
          }`
        else if (node?.data?.target?.sys?.contentType?.sys?.id === 'componentLink') {
          path = node?.data?.target?.fields.url
        } else if (node.nodeType === 'entry-hyperlink') {
          path = node.data?.target?.fields?.slug || node.data?.target?.fields?.url
        }

        const { currentLocale } = useLocale(page.url)

        path = localizedPath(path, currentLocale)

        // localize slugs are not correct atm
        if (
          currentLocale !== defaultLocale &&
          path.includes('#') &&
          !path.includes('#osano') &&
          path.startsWith('/') &&
          !path.startsWith('//')
        ) {
          const currentPathname = page.url
          const targetPathname = new URL(`https://bitwarden.com${path}`)

          if (localizedPath(currentPathname, defaultLocale) === localizedPath(targetPathname, defaultLocale)) path = ''
          else path = targetPathname
        }

        if (!path) {
          return <RichTextComponent body={node.content[0]} />
        }
        return (
          <Link
            to={path || ''}
            className={cx(
              'underline',
              backgroundColor === colors.white && 'text-primaryBlue',
              backgroundColor === colors.lightGray && 'text-primaryBlue',
              backgroundColor === colors.primaryBlue && 'text-white',
              backgroundColor === colors.indigoBlue && 'text-tealBlue',
              'dark:text-tealBlue dark:hover:text-white'
            )}
          >
            <RichTextComponent body={node.content[0]} />
          </Link>
        )
      }, []),
    },
    renderMark: {
      ['bold']: useCallback(bold, []),
      ['code']: useCallback(code, []),
    },
    renderText: (text) =>
      text
        ?.split('\n')
        ?.reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], []) ??
      ' ',
  }

  // here we look for INLINES.ENTRY_HYPERLINK nodes that are succeeded by a #hash-tag
  const toCrawl: Array<Document | Inline | TextType | Block> = [body]

  while (toCrawl.length > 0) {
    const node = toCrawl.shift()

    if (node?.nodeType === INLINES.ENTRY_HYPERLINK && toCrawl.length > 0) {
      const [nextNode] = toCrawl
      if (nextNode.nodeType === 'text') {
        const [hash] = nextNode.value.match(/^#[\w-]+/) || []
        if (hash && node.content?.[0]) {
          Object.assign(node.content[0], { hash })
          nextNode.value = nextNode.value.substring(hash.length)
        }
      }
    }

    if (node?.nodeType !== 'text' && node?.content) toCrawl.push(...node.content)
  }
  try {
    return documentToReactComponents(body, optionsObj)
  } catch (e) {
    console.log(e.message)
  }
}
