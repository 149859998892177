import { useEffect, useState } from 'react'
import { useScript } from 'usehooks-ts'
import type { Entry } from 'contentful'
import RichText from '@/components/ui/rich-text.tsx'
//@ts-ignore
import Spinner from '@/icons/bwi/spinner.svg'
import type { TypeComponentHubSpotFormSkeleton } from '@/types/ctf'
import './hubspot-form.css'

export type HubspotFormProps = Entry<TypeComponentHubSpotFormSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>['fields']

export default function HubspotForm({
  formId,
  headline,
  submitText,
  afterSubmitText,
  // afterSubmitLink,
  translations,
  subtext,
  plausibleEventName,
}: HubspotFormProps) {
  // TODO: Implement get locale
  // const { currentLocale } = useLocale()
  const currentLocale = 'en-US'

  const targetId = `hsf-${formId}`
  const [loaded, setLoaded] = useState(false)

  const scriptStatus = useScript(`//js.hsforms.net/forms/embed/v2.js`, {
    removeOnUnmount: false,
  })
  useEffect(() => {
    if (scriptStatus === 'ready') {
      setLoaded(true)
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '22371289',
        formId: `${formId}`,
        pageId: formId,
        target: `#${targetId}`,
        locale: currentLocale,
        cssClass: plausibleEventName,
        ...(afterSubmitText && { inlineMessage: afterSubmitText }),
        // TODO: Implement localizedPath
        // ...(afterSubmitLink?.url &&
        //   !afterSubmitText && { redirectUrl: localizedPath(afterSubmitLink.url, currentLocale) }),
        translations: {
          [currentLocale]: {
            submitText,
            fieldLabels:
              translations
                ?.filter((t) => !t?.fields?.key?.startsWith('#'))
                .reduce(
                  (acc, curr) => {
                    if (curr?.fields?.key && curr?.fields?.phrase) {
                      acc[curr.fields.key] = curr?.fields?.phrase
                    }
                    return acc
                  },
                  {} as Record<string, string>
                ) || {},
          },
        },
        onFormReady: () => {
          translations
            ?.filter((t) => t?.fields.key.startsWith('#'))
            .forEach((t) => {
              const el = t?.fields.key ? document.getElementById(t?.fields.key.substring(1)) : null
              if (el && t?.fields.phrase) el.innerText = t.fields.phrase
            })
        },
      })
    }
  }, [scriptStatus])

  return (
    <div className="min-h-[10rem] hubspot-form" data-targetid={targetId} data-formid={formId}>
      {headline && <h3>{headline}</h3>}
      {subtext && <RichText body={subtext} />}
      {!loaded && (
        <div className="w-full flex justify-center p-8 hubspot-form-loader">
          <Spinner className="animate-spin h-24 w-24" />
        </div>
      )}

      <div id={targetId} />
    </div>
  )
}
