import { useState } from 'react'
import { useIsomorphicLayoutEffect } from 'framer-motion'
import useDebouncedCallback from './useDebouncedCallback'

type UseElementScrollInfoProps = {
  /**
   * (Optional) The delay to check the scroll info. Prevents layout thrashing from checking values too often
   *
   * See https://gist.github.com/paulirish/5d52fb081b3570c81e3a
   */
  debounceDelay?: number
}

type ScrollInfo = {
  /** Is element overflowing horizonatally */
  isScrollableX: boolean
  /** Is element overflowing vertically */
  isScrollableY: boolean
  /** Scroll distance to left side. -1 if not overflowing */
  scrollLeft: number
  /** Scroll distance to right side. -1 if not overflowing */
  scrollRight: number
  /** Scroll distance to top side. -1 if not overflowing */
  scrollTop: number
  /** Scroll distance to bottom side. -1 if not overflowing */
  scrollBottom: number
}

const useElementScrollInfo = (
  { debounceDelay = 100 }: UseElementScrollInfoProps = { debounceDelay: 100 }
): [(node: HTMLElement | null) => void, ScrollInfo] => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [element, setRef] = useState<HTMLElement | null>(null)

  const initialInfo = {
    isScrollableX: false,
    isScrollableY: false,
    scrollLeft: -1,
    scrollRight: -1,
    scrollBottom: -1,
    scrollTop: -1,
  }
  const [scrollInfo, setScrollInfo] = useState<ScrollInfo>(initialInfo)

  const updateScrollInfo = useDebouncedCallback(() => {
    const info: ScrollInfo = { ...initialInfo }
    if (element) {
      info.isScrollableX = element.scrollWidth > element.clientWidth
      info.isScrollableY = element.scrollHeight > element.clientHeight
      if (info.isScrollableX) {
        info.scrollLeft = element.scrollLeft
        info.scrollRight = element.scrollWidth - element.scrollLeft - element.clientWidth
      }
      if (info.isScrollableY) {
        info.scrollTop = element.scrollTop
        info.scrollBottom = element.scrollHeight - element.scrollTop - element.clientHeight
      }
    }
    setScrollInfo(info)
  }, debounceDelay)

  useIsomorphicLayoutEffect(() => {
    if (element) {
      updateScrollInfo()
      element.addEventListener('scroll', updateScrollInfo)
      window.addEventListener('resize', updateScrollInfo)
      return () => {
        element.removeEventListener('scroll', updateScrollInfo)
        window.removeEventListener('resize', updateScrollInfo)
      }
    }
  }, [element])

  return [setRef, scrollInfo]
}

export default useElementScrollInfo
