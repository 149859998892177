import { useCallback } from 'react'

import useElementScrollInfo from '@/utils/useElementScrollInfo'
import { cx } from '@/utils/strings'

type Directions = 'left' | 'right' | 'top' | 'bottom'
type DirectionsMap = {
  [key in Directions]?: boolean
}

type Corners = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
type CornersMap = {
  [key in Corners]?: boolean
}

type IndicatorProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  position: Directions
  // eslint-disable-next-line react/no-unused-prop-types
  isVisible: boolean
}

type TwCss = any

export type OverflowIndicatorProps = {
  children: JSX.Element
  className?: string
  css?: TwCss | TwCss[]
  /** The scroll distance threshold to display the indicator */
  threshold?: number
  /** Extra css styles to add to the inner scroll container */
  scrollContainerCSS?: TwCss
  /** Extra css styles to add to the indicator elements */
  indicatorCSS?: TwCss | (({ position, isVisible }: IndicatorProps) => TwCss)
  disableOverflowX?: boolean
  disableOverflowY?: boolean
  /** The directions which to show the indicators. By default all directions are enabled */
  enabledDirections?: DirectionsMap
  /** Control which corners are rounded or not. Defaults to all rounded */
  roundedCorners?: boolean | CornersMap
  /** The background color of the container. Also adjusts the color of the indicator */
  bgColor?: null | 'primaryBlue' | 'indigoBlue' | 'lightGray' | 'darkGray' | 'white'
}

/** An overflow container that will automatically display visual indicators when there is overflow content in a given direction */
export default function OverflowIndicator({
  children,
  className,
  css,
  threshold = 4,
  scrollContainerCSS,
  indicatorCSS,
  disableOverflowX = false,
  disableOverflowY = false,
  enabledDirections = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  },
  bgColor = null,
  roundedCorners = true,
}: OverflowIndicatorProps) {
  const [containerRef, { isScrollableX, isScrollableY, scrollLeft, scrollRight, scrollTop, scrollBottom }] =
    useElementScrollInfo()
  const shouldShowLeft = enabledDirections.left && isScrollableX && scrollLeft > threshold
  const shouldShowRight = enabledDirections.right && isScrollableX && scrollRight > threshold
  const shouldShowTop = enabledDirections.top && isScrollableY && scrollTop > threshold
  const shouldShowBottom = enabledDirections.bottom && isScrollableY && scrollBottom > threshold

  const rounded: CornersMap =
    typeof roundedCorners === 'boolean' && roundedCorners
      ? {
          topLeft: true,
          topRight: true,
          bottomLeft: true,
          bottomRight: true,
        }
      : {}

  const Indicator = useCallback(
    ({ position = 'left', isVisible = false }: IndicatorProps) => (
      <div
        className={cx(
          `absolute opacity-0 z-10 transition-opacity duration-200 ease-out pointer-events-none`,
          position === 'left' && `h-full w-8 top-0 left-0 bg-gradient-to-r`,
          position === 'left' && rounded.topLeft && `rounded-tl-[1.6rem]`,
          position === 'left' && rounded.bottomLeft && `rounded-bl-[1.6rem]`,
          position === 'right' && `h-full w-8 top-0 right-0 bg-gradient-to-l z-[11]`,
          position === 'right' && rounded.topRight && `rounded-tr-[1.6rem]`,
          position === 'right' && rounded.bottomRight && `rounded-br-[1.6rem]`,
          position === 'top' && `w-full h-8 top-0 bg-gradient-to-b`,
          position === 'top' && rounded.topLeft && `rounded-tl-[1.6rem]`,
          position === 'top' && rounded.topRight && `rounded-tr-[1.6rem]`,
          position === 'bottom' && `w-full h-8 bottom-0 bg-gradient-to-t`,
          position === 'bottom' && rounded.bottomLeft && `rounded-bl-[1.6rem]`,
          position === 'bottom' && rounded.bottomRight && `rounded-br-[1.6rem]`,
          bgColor === 'primaryBlue' && `from-indigoBlue`,
          bgColor === 'indigoBlue' && `from-black`,
          bgColor === 'lightGray' && `from-gray-500`,
          bgColor === 'darkGray' && `from-white`,
          bgColor === 'white' && `from-gray-500`,
          bgColor === null && `from-gray-500`,
          isVisible && bgColor === 'primaryBlue' && `opacity-30`,
          isVisible && bgColor === 'indigoBlue' && `opacity-30`,
          isVisible && bgColor === 'darkGray' && `opacity-30`,
          isVisible && bgColor === 'lightGray' && `opacity-30`,
          isVisible && bgColor === 'white' && `opacity-20`,
          isVisible && bgColor === null && `opacity-20`,
          typeof indicatorCSS === 'function' ? indicatorCSS?.({ position, isVisible }) : indicatorCSS
        )}
        data-testid={`overflow-indicator-${position}`}
      />
    ),
    [bgColor, roundedCorners, indicatorCSS]
  )

  return (
    <div
      className={cx(
        className,
        `relative overflow-hidden`,
        rounded.topLeft && `rounded-tl-3xl`,
        rounded.topRight && `rounded-tr-3xl`,
        rounded.bottomLeft && `rounded-bl-3xl`,
        rounded.bottomRight && `rounded-br-3xl`,
        bgColor === 'primaryBlue' && `bg-primaryBlue`,
        bgColor === 'indigoBlue' && `bg-indigoBlue`,
        bgColor === 'darkGray' && `bg-gray-800`,
        bgColor === 'lightGray' && `bg-gray-100`,
        bgColor === 'white' && `bg-white`,
        css
      )}
    >
      <div
        ref={containerRef}
        className={cx(
          `w-full h-full`,
          !disableOverflowX && !disableOverflowY && `overflow-auto`,
          !disableOverflowX && disableOverflowY && `overflow-x-auto overflow-y-clip`,
          disableOverflowX && !disableOverflowY && `overflow-y-auto overflow-x-clip`,
          scrollContainerCSS
        )}
        data-testid="overflow-indicator-scroll-container"
      >
        {enabledDirections.left && !disableOverflowX && <Indicator position="left" isVisible={shouldShowLeft} />}
        {enabledDirections.right && !disableOverflowX && <Indicator position="right" isVisible={shouldShowRight} />}
        {enabledDirections.top && !disableOverflowY && <Indicator position="top" isVisible={shouldShowTop} />}
        {enabledDirections.bottom && !disableOverflowY && <Indicator position="bottom" isVisible={shouldShowBottom} />}
        {children}
      </div>
    </div>
  )
}
