import { useCallback, type ReactNode } from 'react'

import RichText from '@/components/ui/rich-text'
import Link from '@/components/link-component'
import ImageContentful from '@/components/ui/image-contentful'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'
import { type TypeComponentQuoteSkeleton } from '@/types/ctf/TypeComponentQuote'
import type { Entry } from 'contentful'
import { useLiveUpdates } from '@/utils/live-preview'

type QuoteEntry = Entry<TypeComponentQuoteSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type QuoteProps = QuoteEntry['fields'] & {
  citation?: string
  imageAspectRatio?: number
  layout?: 'horizontal' | 'vertical'
  entryId?: string
}

export default function Quote({
  citation = '',
  imageAspectRatio = 16 / 9,
  layout = 'vertical',
  entryId,
  ...fields
}: QuoteProps) {
  const {
    fields: { attribution, backgroundColor, body, image, link },
  } = useLiveUpdates({ fields, sys: { id: entryId } })

  const LinkWrap = useCallback(
    ({ children, to }: { children: ReactNode; to?: string }) => (
      <>
        {to ? (
          <Link className="w-full" to={to}>
            {children}
          </Link>
        ) : (
          children
        )}
      </>
    ),
    []
  )
  const { indigoBlue, primaryBlue, lightGray, white } = colors

  return (
    <LinkWrap to={link && link.fields.url}>
      <figure
        className={cx(
          'text-center bg-indigoBlue text-white p-8 mb-1 rounded-3xl',
          (backgroundColor === indigoBlue || backgroundColor === null) && `bg-indigoBlue`,
          backgroundColor === primaryBlue && `bg-primaryBlue`,
          backgroundColor === lightGray && `bg-gray-100 text-indigoBlue`,
          backgroundColor === white && `bg-white text-indigoBlue`,
          layout === 'horizontal' &&
            `h-full flex flex-col sm:flex-row sm:items-center sm:justify-center p-[36px 38px 38px 39px] md:p-[60px 32px 60px 60px]`
        )}
      >
        {image && (
          <div
            className={cx(
              `flex items-center overflow-hidden [&_svg]:m-auto mb-5 rounded-xl pointer-events-none`,
              layout === 'horizontal' ? `w-full h-[] sm:w-5/12 sm:mr-10 mb-8 sm:!mb-0` : `w-1/2 !m-auto`
            )}
            style={{
              aspectRatio: `${imageAspectRatio}` || '16 / 9',
            }}
          >
            <ImageContentful {...image.fields} className="w-full" />
          </div>
        )}
        <div
          className={cx(
            'text-indigoBlue flex-1 px-2 md:px-4 pt-8',
            layout === 'horizontal' && `flex flex-col-reverse text-left sm:px-0 pt-0`,
            (backgroundColor === primaryBlue || backgroundColor === indigoBlue || !backgroundColor) && 'text-white'
          )}
        >
          <blockquote className="text-base leading-normal font-normal inline-block [p:last-child]:mb-0 ">
            <RichText body={body} />
          </blockquote>
          <figcaption
            className={cx(
              'font-bold leading-tight [cite]:not-italic [cite]:block',
              layout === 'horizontal' && `text-2xl sm:text-3xl font-normal leading-[1.2] sm:leading-[1.1] mb-2 sm:mb-7`
            )}
          >
            <cite className="mt-4">{attribution}</cite>
            {citation && (
              <>
                {', '}
                <cite>{citation}</cite>
              </>
            )}
          </figcaption>
        </div>
      </figure>
    </LinkWrap>
  )
}
