import CopyButton from '@/components/copy-button'
import { cx } from '@/utils/strings'

export interface Props {
  contentful_id: string
  type: 'info' | 'warning' | 'success' | 'example'
  theme?: 'light' | 'dark'
  children: JSX.Element
}

// export const fragment = graphql`
//   fragment Callout on ContentfulComponentCallout {
//     __typename
//     contentful_id
//     type
//     body {
//       raw
//       references {
//         ... on ContentfulAsset {
//           gatsbyImage(width: 850)
//         }
//         ...Asset
//         ...Code
//         ... on ContentfulComponentImage {
//           ...ComponentImage
//           image {
//             gatsbyImage(width: 1400)
//           }
//         }
//         ...HubSpotForm
//         ...Icon
//         ...HelpArticleLink
//       }
//     }
//   }
// `

export default function Callout({ contentful_id: id, type, children, theme = 'dark' }: Props): JSX.Element {
  // const { t } = useTranslation()

  const title = {
    warning: 'warning',
    info: 'note',
    success: 'tip',
    example: 'example',
  }
  const icons = {
    warning: '\ue94b',
    info: '\ue952',
    success: '\ue953',
    example: '\ue953',
  }
  return (
    <div
      className={cx(
        'group',
        `px-4 pt-2 mb-4 border border-l-4 border-gray-300 rounded-2xl break-inside-avoid-page`,
        type === 'warning' && `border-l-orange-400`,
        type === 'info' && `border-l-primaryBlue`,
        type === 'success' && `border-l-green-700`,
        type === 'example' && `border-l-indigoBlue`,
        theme === 'light' ? `bg-white` : `bg-gray-200`
      )}
    >
      {type === 'example' && (
        <div className="float-right">
          <CopyButton id={id} theme="light" />
        </div>
      )}

      <h5
        className={cx(
          `flex items-center mb-2 text-2xl font-medium capitalize`,
          type === 'warning' && `text-orange-400`,
          type === 'info' && `text-primaryBlue`,
          type === 'success' && `text-green-700`,
          type === 'example' && `text-indigoBlue`
        )}
      >
        <span className="font-bwi mr-1">{icons[type]}</span>
        {title[type]}
      </h5>
      <section id={id}>{children}</section>
    </div>
  )
}
