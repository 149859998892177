import { useState } from 'react'
import { copyHtmlToClipBoard, cx } from '@/utils/strings'

export interface Props {
  id?: string
  text?: string
  theme?: 'dark' | 'light'
  language?: string
  className?: string
}

export default function CopyButton({ id, text, theme = 'dark' }: Props) {
  const [copied, setCopied] = useState(false)

  return (
    <button
      type="button"
      className={cx(
        'flex items-center justify-center opacity-80 hover:!opacity-100 transition-opacity',
        theme === 'dark' ? 'text-tealBlue' : 'text-primaryBlue'
      )}
      title="Copy Text"
      aria-label="Copy Text"
      onClick={(): void => {
        text ? navigator.clipboard.writeText(text) : copyHtmlToClipBoard(document.getElementById(id).innerHTML)
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
      }}
    >
      <span className="text-[0.9375rem] italic mr-2">{copied ? 'Copied!' : 'Copy'}</span>
      <span className="font-bwi">&#xe905;</span>
    </button>
  )
}
