import { useMemo } from 'react'

// import { Global } from '@emotion/react'

import hljs from 'highlight.js/lib/core'

import bash from 'highlight.js/lib/languages/bash'
import csharp from 'highlight.js/lib/languages/csharp'
import cpp from 'highlight.js/lib/languages/cpp'
import go from 'highlight.js/lib/languages/go'
import java from 'highlight.js/lib/languages/java'
import javascript from 'highlight.js/lib/languages/javascript'
import plaintext from 'highlight.js/lib/languages/plaintext'
import php from 'highlight.js/lib/languages/php'
import python from 'highlight.js/lib/languages/python'
import ruby from 'highlight.js/lib/languages/ruby'
import rust from 'highlight.js/lib/languages/rust'
import xml from 'highlight.js/lib/languages/xml'
import yaml from 'highlight.js/lib/languages/yaml'

import CopyButton from '@/components/copy-button'
import { cx } from '@/utils/strings'

import './code.css'

export interface Props {
  body: {
    body: string
  }
  language?: string
  wordWrap?: boolean
}
export default function Code({ body, language: langStr = 'Bash', wordWrap }: Props): JSX.Element {
  const highlightedCode = useMemo(() => {
    const languageMap = {
      Bash: {
        key: 'bash',
        file: bash,
        extraKeywords: ['bw', 'bws', 'alias', 'chmod', 'echo', 'export', 'npm', 'yarn'],
      },
      'C++': {
        key: 'cpp',
        file: cpp,
      },
      'C#': {
        key: 'csharp',
        file: csharp,
      },
      Go: {
        key: 'go',
        file: go,
      },
      HTML: {
        key: 'xml',
        file: xml,
      },
      Java: {
        key: 'java',
        file: java,
      },
      JavaScript: {
        key: 'javascript',
        file: javascript,
      },
      'Plain Text': {
        key: 'plaintext',
        file: plaintext,
      },
      PHP: {
        key: 'php',
        file: php,
      },
      Python: {
        key: 'python',
        file: python,
      },
      Ruby: {
        key: 'ruby',
        file: ruby,
      },
      Rust: {
        key: 'rust',
        file: rust,
      },
      XML: {
        key: 'xml',
        file: xml,
      },
      YAML: {
        key: 'yaml',
        file: yaml,
      },
    }

    const language = languageMap[langStr] || languageMap['Bash']

    hljs.registerLanguage(language.key, language.file)
    if (language.extraKeywords) {
      hljs.getLanguage(language.key).keywords += ` ${language.extraKeywords.join(' ')}`
    }

    return hljs.highlight(body, { language: language.key }).value
  }, [body, langStr])

  return (
    <div className="mb-4 relative w-full">
      <pre className="flex flex-col text-white leading-[2] bg-gray-800 rounded-2xl overflow-hidden group">
        <div className="flex-[1 0 auto] bg-gray-700 border-b-[1px] border-[#545E66] px-4 pt-2 pb-1 flex justify-between items-center">
          <span className="text-xs italic text-tealBlue">{langStr}</span>
          <CopyButton text={body} />
        </div>

        <div className="bw-code-container flex-[1 0 auto] w-full h-full overflow-auto">
          <div className="flex pb-2 box-content">
            {/* eslint-disable-next-line react/no-danger */}
            <code
              className={cx('pl-4 pr-8 pt-4 pb-2 block', wordWrap && 'text-wrap break-normal')}
              dangerouslySetInnerHTML={{ __html: highlightedCode }}
            />
          </div>
        </div>
      </pre>
    </div>
  )
}
