import { useId, useState } from 'react'

import { smoothScroll } from '@/utils/dom'
import { cx } from '@/utils/strings'

export default function TabGroup({ children }): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(0)
  const uid = useId().slice(1, -1)

  return (
    <div className="mb-16">
      <ul className="flex flex-wrap mb-px lg:-mb-0.5 list-none rounded-md lg:rounded-none" role="tablist">
        {children.map((item, index) => (
          <li key={`${uid}_tab_${index}`}>
            <button
              type="button"
              role="tab"
              data-id={`${uid}_tab_${index}`}
              id={item.props.tabId}
              aria-selected={selectedTab === index ? 'true' : 'false'}
              aria-controls={`${uid}_tabpanel_${index}`}
              tabIndex={selectedTab === index ? undefined : -1}
              className={cx(
                'tab-header mt-px transition lg:mt-0 text-base mb-0.5 rounded-t-md lg:mb-0 mr-[3px] px-4 py-2 !leading-tight text-sm lg:text-base border-2 border-gray-300 border-b-gray-300 lg:border-b-transparent text-gray-700',
                selectedTab === index ? 'font-medium bg-gray-100' : 'font-normal bg-gray-300 hover:bg-gray-200'
              )}
              onClick={(e) => {
                setSelectedTab(index)
                smoothScroll(e, 1)
              }}
              onKeyDown={(e) => {
                let move = 0
                if (e.code === 'ArrowRight') move = 1
                else if (e.code === 'ArrowLeft') move = -1
                if (move !== 0) {
                  let newIndex = selectedTab + move
                  if (newIndex < 0) newIndex = children.length - 1
                  else if (newIndex >= children.length) newIndex = 0
                  const elem = document.querySelector(`[data-id='${uid}_tab_${newIndex}']`) as HTMLInputElement | null
                  elem.click()
                  elem.focus()
                  e.preventDefault()
                }
              }}
            >
              {item.props.buttonContent}
            </button>
          </li>
        ))}
      </ul>
      <div id="tab-parent">
        {children.map((item, index) => (
          <div
            role="tabpanel"
            key={`${uid}_tabpanel_${index}`}
            id={`panel-${item.props.tabId}`}
            aria-labelledby={`${uid}_tab_${index}`}
            className={cx(
              'tab-panel p-4 bg-gray-100 border-2 border-gray-300 rounded',
              selectedTab === index ? 'block' : 'hidden'
            )}
          >
            {item.props.children}
          </div>
        ))}
      </div>
    </div>
  )
}

export function TabItem(props: { children: JSX.Element; buttonContent: JSX.Element; tabId: string }): JSX.Element {
  return props.children
}
