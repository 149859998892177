export interface Props {
  unicode: string
  cssProp?: string
  title?: string
  className?: string
}

// export const fragment = graphql`
//   fragment Icon on ContentfulComponentIcon {
//     __typename
//     contentful_id
//     title
//     unicode
//   }
// `

export default function IconFont({ unicode, title, cssProp, ...other }: Props) {
  return unicode ? (
    <span
      title={title || undefined}
      style={{
        fontFamily: 'bwi-font',
        fontSize: 'inherit',
        pointerEvents: 'none',
      }}
      className={cssProp}
      {...other}
    >
      {String.fromCodePoint(parseInt(unicode, 16))}
    </span>
  ) : null
}
